import "./roadmap.css";

const Roadmap = () => {
  const openLink = () => {
    window.open("https://whitepaper.todaysworldofficial.com/");
  };

  return (
    <div className="roadmap-container">
      <div className="roadmap-title">THE ROADMAP</div>
      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">CONCEPT & ARTWORK</div>
        </div>
        <div className="first-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            Social Media Channels
          </div>
          <div className="last-section-text">
            <div className="dot" />
            Discord Community & Whitelist Blast-Off
          </div>
        </div>
      </div>

      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">
            METAVERSE ART GALLERY <br /> OPENING
          </div>
        </div>
        <div className="roadmap-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            Today’s World Central Gallery
          </div>
          <div className="right-section-text">
            <div className="dot" />
            Full 1020 NFT Paintings Art Collection Reveal
          </div>
          <div className="last-section-text">
            <div className="dot" />
            All degens get officially whitelisted via Maiar on the Website
          </div>
        </div>
      </div>

      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">
            LEGENDARY PHYSICAL NFT <br /> ART STORE
          </div>
        </div>
        <div className="roadmap-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            51 Legendary NFT Paintings Reveal
          </div>
          <div className="right-section-text">
            <div className="dot" />
            The Legendary Physical Paintings are available to Purchase in
            Real-Life
          </div>
          <div className="last-section-text">
            <div className="dot" />
            All Income from the Store is transparently spread back to Community
          </div>
        </div>
      </div>

      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">CSR – 1</div>
        </div>
        <div className="roadmap-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            <div className="left-section-text"></div>Public Donation Event for
            20 NFT Paintings in the Metaverse
          </div>
          <div className="right-section-text">
            <div className="dot" />
            All funds to be transparently
            <b>
              <i> donated (FOUNDATION T.B.A.)</i>
            </b>
          </div>
          <div className="last-section-text">
            <div className="dot" />
            Each 5 EUR / 0.1 EGLD donated is 1 chance to receive by Airdrop one
            NFT Painting
          </div>
        </div>
      </div>

      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">PRE-SALE | Mint Round #1</div>
        </div>
        <div className="roadmap-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            1000 Items available to
            <b>
              <i> mint</i>
            </b>
            , collect and trade (via XOXNO)
          </div>
          <div className="right-section-text">
            <div className="dot" />
            300 Whitelist Spots – Mint 1 get 1 for Free
          </div>
          <div className="last-section-text">
            <div className="dot" />
            All Portals Release in the Metaverse
          </div>
        </div>
      </div>

      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">CSR – 2</div>
        </div>
        <div className="roadmap-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            Public Donation Event for 20 NFT Paintings in the Metaverse
          </div>
          <div className="right-section-text">
            <div className="dot" />
            All funds to be transparently
            <b>
              <i> donated (FOUNDATION T.B.A.)</i>
            </b>
          </div>
          <div className="last-section-text">
            <div className="dot" />
            Each 5 EUR / 0.1 EGLD donated is 1 chance to receive by Airdrop one
            NFT Painting
          </div>
        </div>
      </div>

      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">SUPPORT YOUNG TALENT</div>
        </div>
        <div className="roadmap-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            All royalties received after the 1st Mint Round will be be
            transparently DONATED in FIAT
            <b>
              <i> (FOUNDATION T.B.A.) </i>
            </b>
            to support the romanian artists.
          </div>
          <div className="last-section-text">
            <div className="dot" />
            Unarte Academy to support the Romanian artists.
          </div>
        </div>
      </div>

      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">RAFFLE | Mint Round #2</div>
        </div>
        <div className="roadmap-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            1800 Items available to
            <b>
              <i> mint</i>
            </b>
            , collect and trade (via XOXNO )
          </div>
          <div className="last-section-text">
            <div className="dot" />
            300 Additional Whitelist Spots – Each Wallet receives 1 FREE NFT
            Item
          </div>
        </div>
      </div>

      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">
            VIP Event & NFT PAINTINGS AIRDROP
          </div>
        </div>
        <div className="roadmap-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            Grandious SECRET Party will
            <b>
              <i> host </i>
            </b>
            top holders in a unique location.
          </div>
          <div className="last-section-text">
            <div className="dot" />
            NFT Paintings Airdrop Moment for successful collectors.
          </div>
        </div>
      </div>

      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">PUBLIC ART GALLERY</div>
        </div>
        <div className="roadmap-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            The NFT Paintings will be available to purchase in the Metaverse.
          </div>
          <div className="last-section-text">
            <div className="dot" />
            Collectors will have complete freedom on selling / holding their NFT
            Painting.
          </div>
        </div>
      </div>

      <div className="roadmap-section">
        <div className="left-section">
          <div className="left-section-text">TOMORROW’S WORLD</div>
        </div>
        <div className="last-divider" />
        <div className="right-section">
          <div className="right-section-text">
            <div className="dot" />
            All future royalties of Today’s World NFT Project will be DONATED in
            full
            <b>
              <i> (FOUNDATION T.B.A.) </i>
            </b>
            for as long as
            <b>
              <i> multiversx (elrond) </i>
            </b>
            blockchain will exist.
          </div>
        </div>
      </div>
      <button className="whitepaper-button" onClick={openLink}>
        <div className="whitepaper-button-text">EXPLORE THE WHITEPAPER</div>
      </button>
    </div>
  );
};

export default Roadmap;
