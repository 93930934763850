import { useEffect, useMemo, useState } from "react";

export const OPTIONS = {
  rootMargin: "0px",
  threshold: 0.7
};

export const useIsInViewport = ref => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        OPTIONS
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};
