import { useEffect, useRef } from "react";
import { useIsInViewport } from "../../utils/viewport";
import "./mission.css";

const Mission = () => {
  const videoRef = useRef();
  const isInViewport = useIsInViewport(videoRef);

  useEffect(() => {
    if (isInViewport) {
      videoRef.current.play();
    } else if (!isInViewport) {
      videoRef.current.pause();
    }
  }, [isInViewport]);

  return (
    <div className="mission-container">
      <div className="mission-title">OUR MISSION</div>
      <div className="mission-text">
        We are not afraid to SAY IT OUT LOUD: <br />
        THE SOCIETY IS BROKEN.
      </div>
      <div className="mission-text">
        Today’s World stereotypes: Money Greed, Drugs Addiction, Poverty Spread,
        <br />A GLOBAL Political Crisis, the Brands Culture Obsession.
      </div>
      <div className="mission-text">
        We thrive to express all these themes through art <br />
        and spread it to the world through NFT technology.
      </div>
      <div className="mission-text">
        Our NFT art sales will serve the people with donations. <br />
        young artists will benefit from all royalties of the project <br />
        so that more talents will join our mission.
      </div>
      <div className="mission-last-text">
        We put a patch on today's world wounds to build a better tomorrow's
        world.
      </div>
      <video
        ref={videoRef}
        src="https://storage.googleapis.com/regage/public/todaysworld-3.mp4"
        className="mission-video"
        title="mission"
        muted
        playsInline
        loop
      />
    </div>
  );
};

export default Mission;
