import { useEffect, useRef, useState } from "react";
import { useIsInViewport } from "../../utils/viewport";
import useWindowSize from "../../utils/windowsSize";
import logo from "../../assets/images/logo.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/play.svg";
import { ReactComponent as SoundIcon } from "../../assets/icons/sound.svg";
import { ReactComponent as MutedIcon } from "../../assets/icons/muted.svg";
import "./scrollAnimation.css";

const WEB_ANIMATION_CONFIG = {
  changePositionType: 1.05,
  opacity: 0.5,
  hideAnimation: 0.95,
  videoPlay: 0.7
};

const MOBILE_ANIMATION_CONFIG = {
  changePositionType: 0.75,
  opacity: 0.2,
  hideAnimation: 0.7,
  videoPlay: 0.3
};

const ScrollAnimationComponent = () => {
  const [translateValue, setTranslateValue] = useState(0);
  const videoRef = useRef();
  const videoContaineRef = useRef();
  const isInViewport = useIsInViewport(videoRef);
  const { width } = useWindowSize();
  const [config, setConfig] = useState(
    width > 768 ? WEB_ANIMATION_CONFIG : MOBILE_ANIMATION_CONFIG
  );
  const [videoState, setVideoState] = useState({ muted: true, playing: false });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isInViewport) {
      handlePause();
    }
    // eslint-disable-next-line
  }, [isInViewport]);

  useEffect(() => {
    const newConfig =
      width > 768 ? WEB_ANIMATION_CONFIG : MOBILE_ANIMATION_CONFIG;
    setConfig(newConfig);
  }, [width]);

  const handleScroll = () => {
    if (window.scrollY < window.innerHeight * config.changePositionType) {
      setTranslateValue(window.scrollY);
      toggleVideo();
    } else {
      setTranslateValue(window.innerHeight * 2);
    }
  };

  const toggleVideo = () => {
    const isPlaying =
      videoRef.current.currentTime > 0 && !videoRef.current.paused;
    if (window.scrollY >= window.innerHeight * config.videoPlay && !isPlaying) {
      handlePlay();
    } else if (
      window.scrollY < window.innerHeight * config.videoPlay &&
      videoRef.current?.playing
    ) {
      handlePause();
    }
  };

  const handlePlay = () => {
    videoRef.current.play();
    setVideoState(prevState => ({ ...prevState, playing: true }));
  };

  const handlePause = () => {
    videoRef.current.pause();
    setVideoState(prevState => ({ ...prevState, playing: false }));
  };

  const handleMute = () => {
    videoRef.current.muted = !videoRef.current.muted;
    setVideoState(prevState => ({ ...prevState, muted: !prevState.muted }));
  };

  const getOpacityStyle = () => {
    if (window.scrollY < window.innerHeight * config.opacity) {
      return { display: "none" };
    }
    if (window.scrollY > window.innerHeight * config.changePositionType) {
      return { opacity: 1 };
    }
    const scrollDiff = window.scrollY - window.innerHeight * config.opacity;
    const opacity = (2 * (scrollDiff * 100)) / window.innerHeight;
    return { opacity: `${opacity}%` };
  };

  const renderImages = () => (
    <>
      <img src={logo} style={{ width: "70%" }} className="image" alt="logo" />
      <img src={logo} style={{ width: "85%" }} className="image" alt="logo" />
      <img src={logo} style={{ width: "110%" }} className="image" alt="logo" />
    </>
  );

  return (
    <div className="section">
      <div
        className="animationContainer"
        style={{
          display:
            translateValue >= window.innerHeight * config.hideAnimation
              ? "none"
              : "block"
        }}
      >
        <div className="animationTop">
          <div
            className="flexTop"
            style={{
              transform: `translateY(${-translateValue}px)`
            }}
          >
            {renderImages()}
          </div>
        </div>
        <div className="animationBottom">
          <div
            className="flexBottom"
            style={{
              transform: `translateY(${translateValue}px) rotateX(180deg)`
            }}
          >
            {renderImages()}
          </div>
        </div>
      </div>
      <div
        className="videoContainer"
        style={{
          ...(window.scrollY <= window.innerHeight * config.changePositionType
            ? { position: "fixed", height: window.innerHeight }
            : {
                position: "absolute",
                height: window.innerHeight,
                top: window.innerHeight * config.changePositionType
              }),
          ...getOpacityStyle()
        }}
      >
        <div className="videoWrapper" ref={videoContaineRef}>
          <video
            className="video"
            ref={videoRef}
            src="https://storage.googleapis.com/regage/public/todaysworld-1.mp4"
            muted
            playsInline
            loop
          />
          <div className="controls">
            {videoState.playing ? (
              <PauseIcon className="controlIcon" onClick={handlePause} />
            ) : (
              <PlayIcon className="controlIcon" onClick={handlePlay} />
            )}
            {videoState.muted ? (
              <MutedIcon className="controlIcon" onClick={handleMute} />
            ) : (
              <SoundIcon className="controlIcon" onClick={handleMute} />
            )}
          </div>
        </div>
        {/* <video
          ref={videoRef}
          src="https://storage.googleapis.com/regage/public/todaysworld-1.mp4"
          style={{ height: window.innerHeight }}
          className="video"
          controls
          muted
          playsInline
        /> */}
      </div>
      <div
        className="sectionPlaceholder"
        style={{
          height: `calc(100vh + ${Math.max(
            videoContaineRef.current?.offsetHeight,
            window.innerHeight * config.changePositionType
          )}px)`
        }}
      />
    </div>
  );
};

export default ScrollAnimationComponent;
