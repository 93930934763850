import "./App.css";
import Header from "./components/Header/Header";
import ScrollAnimationComponent from "./components/animation/ScrollAnimationComponent";
import Mission from "./components/Mission/Mission";
import Roadmap from "./components/Roadmap/Roadmap";
import Metaverse from "./components/Metaverse/Metaverse";

function App() {
  return (
    <div className="background">
      <Header />
      <ScrollAnimationComponent />
      <Mission />
      <Roadmap />
      <Metaverse />
    </div>
  );
}

export default App;
