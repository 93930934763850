import "./popup.css";
import { socialIcons } from "../Header/Header";

export const Popup = ({ closePopup }) => {
  const openLink = link => () => {
    window.open(link);
  };

  return (
    <div className="popup-container" onClick={closePopup}>
      <div
        className="popup-body"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="mission-text">
          FOLLOW US ON SOCIAL TO FIND OUT ALL RELEASE DATES
        </div>
        <div className="popup-social-container">
          {socialIcons.map((item, index) => (
            <div
              className="popup-social-icon"
              key={index}
              onClick={openLink(item.href)}
            >
              <item.Component style={{ display: "block" }} />
            </div>
          ))}
        </div>
        <div>
          <button className="connect-button" onClick={closePopup}>
            <div className="connect-button-text">Close</div>
          </button>
        </div>
      </div>
    </div>
  );
};
