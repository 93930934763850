import { useEffect, useState } from "react";
import useWindowSize from "../../utils/windowsSize";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as TikTokIcon } from "../../assets/icons/tiktok.svg";
import { ReactComponent as LinktreeIcon } from "../../assets/icons/linktree.svg";
import logo from "../../assets/images/logo.png";
import { Popup } from "../popup/Popup";
import "./header.css";

export const socialIcons = [
  { Component: DiscordIcon, href: "https://discord.gg/PB4AhugXS4" },
  { Component: TwitterIcon, href: "https://twitter.com/todaysworld_nft" },
  {
    Component: InstagramIcon,
    href: "https://www.instagram.com/todaysworld_nft/"
  },
  { Component: TikTokIcon, href: "https://www.tiktok.com/@todaysworld_nft" },
  {
    Component: LinktreeIcon,
    href: "https://linktr.ee/todaysworldnft"
  }
];

const Header = () => {
  const [logoVisible, setLogoVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    window.addEventListener("scroll", logoVisibilityChange);
    return () => {
      window.removeEventListener("scroll", logoVisibilityChange);
    };
  }, []);

  const logoVisibilityChange = () => {
    if (window.scrollY >= window.innerHeight * 0.8) {
      setLogoVisible(true);
    } else if (window.scrollY < window.innerHeight * 0.8) {
      setLogoVisible(false);
    }
  };

  const openLink = link => () => {
    window.open(link);
  };

  const togglePopup = () => {
    setPopupVisible(prev => !prev);
  };

  return (
    <>
      <div className={`header-container ${logoVisible ? "translucent" : ""}`}>
        {width > 768 && (
          <div className="social-container">
            {socialIcons.map((item, index) => (
              <div
                className="social-icon"
                key={index}
                onClick={openLink(item.href)}
              >
                <item.Component style={{ display: "block" }} />
              </div>
            ))}
          </div>
        )}
        <img
          src={logo}
          alt="header-logo"
          className="header-logo"
          style={{ visibility: logoVisible ? "initial" : "hidden" }}
        />
        <button className="connect-button" onClick={togglePopup}>
          <div className="connect-button-text">CONNECT</div>
        </button>
      </div>
      {popupVisible && <Popup closePopup={togglePopup} />}
    </>
  );
};

export default Header;
