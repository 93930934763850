import { useEffect, useRef } from "react";
import { useIsInViewport } from "../../utils/viewport";
import useWindowSize from "../../utils/windowsSize";
import logo from "../../assets/gif/logo.gif";
import "./metaverse.css";

const desktopVideo =
  "https://storage.googleapis.com/regage/public/todaysworld-5.mp4";
const mobileVideo =
  "https://storage.googleapis.com/regage/public/todaysworld-5-mobile.mp4";

const openLink = () => {
  window.open(
    "https://www.spatial.io/s/Todays-World-Metaverse-62fe91245786e500017be16d?share=7751191244098313762"
  );
};

const Metaverse = () => {
  const videoRef = useRef();
  const isInViewport = useIsInViewport(videoRef);
  const { width } = useWindowSize();

  useEffect(() => {
    if (isInViewport) {
      videoRef.current.play();
    } else if (!isInViewport) {
      videoRef.current.pause();
    }
  }, [isInViewport]);

  return (
    <div className="metaverse-container">
      <div className="metaverse-border">
        <video
          ref={videoRef}
          src={width > 768 ? desktopVideo : mobileVideo}
          className="metaverse-video"
          muted
          playsInline
          loop
        />
      </div>
      <button className="metaverse-button" onClick={openLink}>
        <div className="metaverse-button-text">ENTER THE METAVERSE</div>
      </button>
      <img src={logo} alt="logo" className="gif" />
    </div>
  );
};

export default Metaverse;
